/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactChild, ReactChildren } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header/Header"
import "../../App.scss"
import Footer from "../Footer/Footer"

interface LayoutType {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[]
  darkNavbar?: boolean
  hideForm?: boolean
}

const Layout = ({ children, darkNavbar, hideForm }: LayoutType) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        darkNavbar={darkNavbar ?? false}
      />
      <div className="bodyWrapper"
        style={{
          margin: `0 auto`, width: `100%`
        }}
      >
        <main>{children}</main>
        <Footer hideForm={hideForm ?? false} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
