import React, { ReactChild, ReactChildren } from "react"
import "./Columns.scss"

interface ColumnsProps {
  children: ReactChild[] | ReactChildren[]
  widthDividers?: boolean
  className?: string
  style?: React.CSSProperties
  centered?: boolean
}

const Columns = ({
  children,
  widthDividers,
  style = {},
  centered,
  className,
}: ColumnsProps) => {
  return (
    <div className={`Columns ${className ?? ""}`} style={style}>
      {children?.map((child, idx) => (
        <React.Fragment key={idx}>
          <div
            className="Columns__column"
            style={{ justifyContent: centered ? "center" : "flex-start" }}
          >
            {child}
          </div>
          {widthDividers && idx < children.length - 1 && (
            <div className="Columns__divider"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default Columns
