import React from "react"
import "./TopBar.scss"
import { Link } from "gatsby"

interface TopBarProps {
  className?: string
}

const TopBar = ({ className }: TopBarProps) => {
  return (
    <>
      <div className="TopBar">
        <div className="TopBar__wrap">
          <p>
            Learn how Habito brought digital fraud to light.{" "}
             <Link
               to="/case-studies/habito"
               target="_blank"
             >
               Read the case study.
              </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default TopBar
