import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from 'gatsby';
import Columns from "../Columns/Columns"
import Section from "../Section/Section"
import "./Footer.scss"

import investor1 from "../../svg/investor_1.svg"
import investor2 from "../../svg/investor_2.svg"
import investor3 from "../../svg/investor_3.svg"
import twitter from "../../svg/ico_twitter.svg"
import linkedIn from "../../svg/ico_linked.svg"
import koala from "../../svg/footer_koala.svg"
import investor4 from "../../svg/investor_4.svg"
import DemoSection from "../DemoSection/DemoSection"
import Button from "../Button/Button"

interface FooterProps {
  hideForm: boolean
}

const Footer = ({ hideForm }: FooterProps) => {
  return (
    <>

      <div className="Footer">
        <div className={`${ hideForm ? "hidden" : "Footer__demo"} `} id="demo">
          <Section sectionClass="Footer__cta">
            <div className="w-wrap">
               <div className="w1-2">
               <h1>Ready to <span className="highlighted">start a conversation</span>?</h1>
              </div>
              <div className="w1-2">
                <p>Find out how we can help protect your systems from manipulation and attack.</p>
                <Button
                  variant="white"
                  link="/contact"
                  size= "large"
                >
                  Get Started
                </Button>
              </div>
            </div>
          </Section>
        </div>
        <Columns centered widthDividers>
          <div className="Footer__investors">Our investors.</div>

          <img src={investor1} alt="CredO"></img>

          <img src={investor4} alt="Google"></img>

          <img src={investor2} alt="index ventures"></img>

          <img src={investor3} alt="seedcamp"></img>
        </Columns>
        <div className="Footer__bottom-section">
          <div className="Footer__bottom-section__first">
            <div> © {new Date().getFullYear()} Resistant AI</div>
            <div className="Footer__socials">
              <a href="https://twitter.com/resistantai" target="blank"><img src={twitter}/></a>
              <a href="https://www.linkedin.com/company/resistant-ai" target="blank"><img src={linkedIn}/></a>
            </div>
          </div>
          <div style={{ lineHeight: 0 }}>
          <Link className="policyLink" to="/privacy-policy">Privacy Policy</Link>
          <a className="craftLink" href="https://www.koala42.com">
              This website was crafted by <img src={koala} alt="Koala 42" height="20px"></img>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
