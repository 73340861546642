import React from "react"
import { navigate } from "gatsby"
import "./Button.scss"
import { nodeModuleNameResolver } from "typescript"

export interface ButtonProps {
  variant: "green" | "blue" | "transparent" | "white"
  link: string
  size?: "regular" | "large"
  text?: string
  fullWidth?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
  mailLink?: boolean
}
/**
 * Children has priority over the text parameter.
 */
const Button = ({
  text,
  link,
  variant,
  children,
  fullWidth,
  size,
  style,
  mailLink,
}: ButtonProps) => {
  const handleButtonClick = () => {
    navigate(link)
  }
  return (
    <>
      {mailLink ? (
        <a
          style={{ ...style, textDecoration: "none" }}
          className={`Button__${variant} ${
            fullWidth ? "Button--fullWidth" : ""
          } Button--${size ? size : "regular"}`}
          href={link}
        >
          {children ? children : text}
        </a>
      ) : (
        <button
          style={style}
          className={`Button__${variant} ${
            fullWidth ? "Button--fullWidth" : ""
          } Button--${size ? size : "regular"}`}
          onClick={handleButtonClick}
        >
          {children ? children : text}
        </button>
      )}
    </>
  )
}

export default Button
