import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import borgirDark from "../../svg/bars-solid-dark.svg";
import borgir from "../../svg/bars-solid.svg";
import chevronBlack from "../../svg/chevron-down-black.svg";
import chevronBold from "../../svg/chevron-down-bold.svg";
import NewsLinkedIn from "../../svg/ico_linkedDark.svg";
import NewsTwitter from "../../svg/ico_twitterDark.svg";
import LogoDark from "../../svg/logo_dark.svg";
import Button from "../Button/Button";
import TopBar from "../TopBar/TopBar";
import "./Header.scss";
interface HeaderProps {
  darkNavbar?: boolean;
}
const Header = ({ darkNavbar }: HeaderProps) => {
  const dropdowns = [
    {
      id: 0,
      name: "Products",
      links: [
        {
          text: "Document Forensics",
          link: "/products/documents",
        },
        {
          text: "Transaction Forensics",
          link: "/products/transactions",
        },
      ],
    },

      {
        id: 1,
        name: "Use Cases",
        links: [
          {
            text: "Anti-money laundering",
            link: "/use-cases/aml",
          },
          {
            text: "Buy Now Pay Later",
            link: "/use-cases/bnpl",
          },
          {
            text: "Document Forgery",
            link: "/products/documents",
          },
        ],
      },

      {
        id: 2,
        name: "Customer Stories",
        links: [
          {
            text: "Habito",
            link: "/case-studies/habito",
          },
        ],
      },

      {
        id: 3,
        name: "Developers",
        links: [
          {
            text: "Document Forensics API",
            link: "https://documents.resistant.ai/docs/v2.html",
            target: "blank",
          },
          {
            text: "Server status",
            link: "https://resistantai.statuspage.io/",
            target: "blank",
          },
        ],
      },

      {
        id: 4,
        name: "Company",
        links: [
          {
            text: "About",
            link: "/about",
          },
          {
            text: "News",
            link: "/news",
          },
          {
            text: "Careers",
            link: "/career",
          },
        ],
      },
  ];

  const [currentDropdown, setCurrent] = useState(undefined);
  const openDropdown = (dropdownId: number) => {
    setCurrent(dropdownId);
  };

  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [isMobileMenuShown, setIsMobileMenuShown] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleMobileMenuShown = () => {
    setIsMobileMenuShown((prev) => !prev);
  };

  const handleContactClick = () => {
    handleMobileMenuShown();
  };

  const handleDropdownVisibility = () => {
      setCurrent(undefined);
  };

  const isVisible = (id: number) => {
    if (id === currentDropdown) return true;
    return false;
  };

    useEffect(() => {
      const checkIfClickedOutside = (e: any) => {
        console.log(e.target.className)
        //@ts-ignore
        if (e.target.className !== "Header__dropdown__link") {
          handleDropdownVisibility();
        }
      };
      document.addEventListener("mousedown", checkIfClickedOutside);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }, [currentDropdown]);


  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let handleScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 0) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) || window.innerWidth < 990
      );
    }
  }, []);

  return (
    <header>
      <div
        className={`Header${darkNavbar ? "--dark" : ""} ${
          isHeaderFixed ? "fixed" : ""
        } ${isMobileMenuShown ? "menuShown" : ""}`}>
        <div className="Header__wrapper">
          <div className="Header__main-wrapper">
            <Link to="/" className="Header__main">
              <img
                src={
                  darkNavbar || isHeaderFixed || (isMobileMenuShown && isMobile)
                    ? LogoDark
                    : LogoDark
                }
                alt="Resistant AI"
                className="Header__main__logo"
              />
            </Link>
            {isMobile && (
              <img
                src={
                  darkNavbar || isMobile || isMobileMenuShown
                    ? borgir
                    : borgirDark
                }
                alt="menu"
                width={24}
                onClick={handleMobileMenuShown}
              />
            )}
          </div>

          {(!isMobile || isMobileMenuShown) && (
            <div className="Header__links">
              {dropdowns.map((dropdown) => {
                return (
                  <div
                    className="Header__dropdown"
                    onClick={() => openDropdown(dropdown.id)}
                    >
                    <div className="Header__dropdown--text">
                      {dropdown.name}
                      <img
                        src={
                          isMobile
                            ? chevronBold
                            : darkNavbar || isHeaderFixed
                            ? chevronBlack
                            : chevronBlack
                        }
                        alt="chevron down"
                        width={16}
                        style={{
                          transform: isVisible(dropdown.id)
                            ? "rotate(180deg)"
                            : "",
                          transition: "transform 0.5s",
                        }}></img>
                    </div>

                    {isVisible(dropdown.id) && (
                      <>
                      <div className="Header__dropdown__links">
                        {dropdown.links.map((link) => (
                            <Link
                              to={link.link}
                              className="Header__dropdown__link" target={link.target}>
                              {link.text}
                            </Link>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}


              {/* <Link to="/faq" className="Header__link">
              FAQ
            </Link> */}
              <div className="Header__button" onClick={handleContactClick}>
                <Button variant="blue" link="/contact">
                  Contact
                </Button>
              </div>
              <div className="Header__socials-wrap">
                <a
                  target="blank"
                  href="https://www.linkedin.com/company/resistant-ai/"
                  className="Header__socials__link">
                  <img src={NewsLinkedIn} alt="LinkedIn" />
                </a>
                <a
                  target="blank"
                  href="https://www.twitter.com/ResistantAI"
                  className="Header__socials__link">
                  <img src={NewsTwitter} alt="Twitter" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <TopBar></TopBar>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
