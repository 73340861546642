import { GatsbyImage } from "gatsby-plugin-image"
import React, { ReactChild, ReactChildren } from "react"
import Button, { ButtonProps } from "../Button/Button"
import "./Section.scss"
//@ts-ignore
import Fade from "react-reveal/Fade"

interface SectionProps {
  title?: string 
  titleHighlight?: string
  subtitle?: string
  text?: string
  button?: ButtonProps
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[]
  image?: any
  quoteBy?: string
  backgroundColor?: string
  style?: React.CSSProperties
  SvgImage?: any
  row?: boolean
  imageStyle?: React.CSSProperties
  centered?: boolean
  className?: string
  sectionClass?: string
  gray?: boolean
  foregroundColor?: "white" | "black"
  id?: string
  sectionStyle?: React.CSSProperties
}

const Section = ({
  title,
  titleHighlight,
  subtitle,
  text,
  children,
  image,
  button,
  quoteBy,
  backgroundColor,
  style = {},
  SvgImage,
  row,
  imageStyle,
  centered,
  className,
  sectionClass,
  gray,
  foregroundColor,
  id,
  sectionStyle,
}: SectionProps) => {
  let adjustment = ""

  if (image || SvgImage || row) {
    adjustment = "--row"
  } else if (centered) {
    adjustment = "--centered"
  }

  return (
    <div
      className={`Section ${sectionClass ?? ""} ${gray ? "gray" : ""}`}
      style={{
        background: backgroundColor,
        color: foregroundColor,
        ...sectionStyle,
      }}
      id={id}
    >
      <div
        className={`Section__wrapper${adjustment} ${className ?? ""}`}
        style={{ ...style }}
      >
        {quoteBy && <div className={`Section__quote`}>{quoteBy}</div>}

        <div className={`Section__lhs${adjustment}`}>
          {title && <h2 className={`Section__title${adjustment}`}>{title}<span className="highlighted">{titleHighlight}</span></h2>}
          {subtitle && (
            <h2
              className={`Section__subtitle${adjustment}`}
              style={{ color: foregroundColor === "white" ? "white" : "" }}
            >
              {subtitle}
            </h2>
          )}
          {text && <div className={`Section__text${adjustment}`}>{text}</div>}
          {children && (image || SvgImage) && (
            <div className={`Section__children${adjustment}`}>{children}</div>
          )}
        </div>

        {image && (
          <GatsbyImage
            alt=""
            image={image}
            className={`Section__image`}
            style={imageStyle}
          />
        )}
        {SvgImage && (
          <Fade up distance="250px">
            <img src={SvgImage} className="Section__image" style={imageStyle} alt="Resistant AI"/>
          </Fade>
        )}
        {children && !image && !SvgImage && (
          <div className={`Section__children${adjustment}`}>{children}</div>
        )}
        {button && (
          <div className="Section__button">
            <Button {...button} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Section
